import axios from 'axios'
import { Auth } from 'aws-amplify'

const axiosInstance = axios.create({
  // TODO: should come from .env
  baseURL: 'https://dev-api.arbo3.com/',
})

axiosInstance.interceptors.request.use(async (config) => {
  const token = await setUpToken()
  config.headers['Authorization'] = token
  return config
})

export const setUpToken = async () => {
  const authenticatedUser = await Auth.currentSession()

  const token = authenticatedUser.getIdToken().getJwtToken()
  return `Bearer ${token}`
}

export default axiosInstance
