import { Navigate, createBrowserRouter } from 'react-router-dom'
import { Layout } from './components/layout'
import Dashboard from './pages/dashboard'
import { ValidRolesType, hasRole, useSession } from './lib/session-utils'
import UserPage from './pages/user'
import Skeleton from 'react-loading-skeleton'
import { PropsWithChildren } from 'react'
import Login from './pages/login'
import Devices from './pages/devices'
import SupportPage from './pages/support'

export const getRoutes = (role: ValidRolesType) => {
  return createBrowserRouter([
    { path: '/login', element: <Login /> },
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>{hasRole(['g1Admin', 'tenantAdmin'], role) ? <Dashboard /> : <UserPage />}</ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/devices',
      element: <Layout />,
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute roles={['g1Admin', 'tenantAdmin']}>
              <Devices />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/support',
      element: <Layout />,
      children: [
        {
          path: '',
          element: <SupportPage />,
        },
      ],
    },
  ])
}

export const ProtectedRoute = ({
  children,
  roles,
  redirectTo = '',
}: PropsWithChildren<{ roles?: ValidRolesType[]; redirectTo?: string }>) => {
  const { isAuthenticated, loading, role } = useSession()

  if (loading) return <Skeleton />
  if (!loading && !isAuthenticated) return <Navigate to="/login" replace />
  if (roles?.length && !hasRole(roles, role)) return <Navigate to={redirectTo || '/'} replace />
  return children
}

export default getRoutes
