import { useTranslation } from 'react-i18next'
import { CiMenuFries } from 'react-icons/ci'
import { Link } from 'react-router-dom'

export const TopNavigation = () => {
  const { t } = useTranslation()
  return (
    <div className="w-full py-4 flex justify-between">
      <p className="text-2xl text-black opacity-50 font-semibold">{t('nav.title')}</p>
      <div className="flex items-center space-x-6">
        <Link to="/">{t('nav.home')}</Link>
        <Link to="/devices">{t('nav.devices')}</Link>
        <Link to="/support">{t('nav.support')}</Link>
        <a href="#my-account">{t('nav.myAccount')}</a>
        <button>
          <CiMenuFries size="1.5rem" />
        </button>
      </div>
    </div>
  )
}

export default TopNavigation
