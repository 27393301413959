import {
  ColumnDef,
  ColumnFiltersState,
  FilterFn,
  Row,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import { DeviceSummary } from 'src/services/ozone-devices/ozone-devices.types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { BsArrowDown, BsArrowDownUp, BsArrowUp } from 'react-icons/bs'
import { useVirtual } from 'react-virtual'
import { Filter, MultiSelectFilter } from './ozone-table-utils'
import { useTranslation } from 'react-i18next'
import DeviceHistoryDialog from '../device-ozone-history'
import { useHasRole } from 'src/lib/session-utils'
import { useQuery as useUrlQuery } from 'src/lib/router-utils'
import { STATUS_LABELS } from 'src/lib/constants'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select'
import { Button } from '../ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import { getSavedFilters, saveFilter } from 'src/services/ozone-devices/ozone-devices'
import { nanoid } from 'nanoid'
import { Input, Popover, Text } from '@mantine/core'
import { useLocation, useNavigate } from 'react-router-dom'

interface OzoneDevicesTableProps {
  devices: DeviceSummary[]
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
  // Rank the item
  const itemRank = rankItem(row.getValue(columnId), value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

const OzoneDevicesTable = ({ devices }: OzoneDevicesTableProps) => {
  const { state } = useLocation()

  const [selectedFilter, setSelectedFilter] = useState<string>(state?.savedFilter?.filterId || 'noFilter')
  const [filterName, setFilterName] = useState<string>()
  const [filterNamePopoverOpen, setFilterNamePopoverOpen] = useState<boolean>(false)
  const queryParams = useUrlQuery()
  const { t } = useTranslation()
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(state?.savedFilter?.filters || [])
  const [urlParamsRan, setUrlParamsRan] = useState<boolean>(false)

  const navigate = useNavigate()

  useEffect(() => {
    navigate('.', { replace: true })
  }, [navigate])

  const { data: filters, refetch: refetchFilters } = useQuery({
    queryKey: ['deviceFilters'],
    queryFn: getSavedFilters,
    retry: false,
  })

  const saveFilterMutation = useMutation({
    mutationFn: (filterName: string) => saveFilter({ filters: columnFilters, filterName, filterId: nanoid() }),
    onSuccess(data) {
      refetchFilters()
      setFilterName('')
      setSelectedFilter(data.filterId)
    },
  })

  const uniqueStatus = useMemo(() => {
    const statuses = new Set<string>()
    devices.forEach((device) => {
      const uniqueStatus = STATUS_LABELS[device.status?.toString() as keyof typeof STATUS_LABELS]?.label
      if (uniqueStatus) {
        statuses.add(uniqueStatus)
      }
    })
    return Array.from(statuses)
  }, [devices])

  const isTenantAdmin = useHasRole(['tenantAdmin'])

  const columns = useMemo<ColumnDef<DeviceSummary, any>[]>(() => {
    const columns: ColumnDef<DeviceSummary, any>[] = [
      {
        accessorKey: 'deviceid',
        id: 'deviceid',
        enableColumnFilter: false,
        cell: (info) => (
          <DeviceHistoryDialog deviceId={info.getValue()}>
            <button className="text-ellipsis block overflow-hidden px-2 py-1 m-auto hover:text-gray-500 transition-colors">
              {info.getValue()}
            </button>
          </DeviceHistoryDialog>
        ),
        header: () => <span>{t('dashboard.devicesTable.headers.deviceNumber')}</span>,
      },
      {
        accessorKey: 'userId',
        id: 'userId',
        cell: (info) => info.getValue(),
        header: () => (
          <span className="text-ellipsis overflow-hidden block">{t('dashboard.devicesTable.headers.userId')}</span>
        ),
      },
      {
        accessorFn: (row) => STATUS_LABELS[row.status?.toString() as keyof typeof STATUS_LABELS].label,
        id: 'status',
        cell: (info) => {
          const value = info.row.original.status
          const cellConfig = STATUS_LABELS[value?.toString() as keyof typeof STATUS_LABELS]
          return (
            <div className={`h-full w-full flex items-center align-middle ${cellConfig?.classes}`}>
              <span className="grow">{cellConfig?.label}</span>
            </div>
          )
        },
        filterFn: 'arrIncludesSome',
        header: () => <span>{t('dashboard.devicesTable.headers.status')}</span>,
      },
      {
        accessorFn: (row) => `${row.ozoneLevel}`,
        id: 'ozoneLevel',
        cell: (info) => info.getValue(),
        header: () => <span>{t('dashboard.devicesTable.headers.ozoneLevel')}</span>,
      },
      {
        accessorKey: 'gps',
        id: 'gps',
        cell: (info) => {
          const { x: lat, y: long } = info.getValue()
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">lat: {Number(lat).toFixed(4)}</span>
              <span className="text-ellipsis overflow-hidden block">lng:{Number(long).toFixed(4)}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.location')}</span>,
      },
      {
        accessorFn: (row) => (row.online ? 'Online' : 'Offline'),
        id: 'online',
        cell: (info) => {
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">{info.getValue()}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.online')}</span>,
      },
      {
        accessorKey: 'ozoneTarget',
        id: 'ozoneTarget',
        cell: (info) => {
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">{info.getValue()}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.ozoneTarget', { defaultValue: 'Ozone Target' })}</span>,
      },
      {
        accessorFn: (row) => `${row.shift.from} ${row.shift.to}`,
        id: 'shift',
        cell: (info) => {
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">{info.getValue()}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.shift', { defaultValue: 'Shift' })}</span>,
      },
      {
        accessorKey: 'city',
        id: 'city',
        cell: (info) => {
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">{info.getValue()}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.city', { defaultValue: 'City' })}</span>,
      },
      {
        accessorKey: 'callNumber',
        id: 'callNumber',
        cell: (info) => {
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">{info.getValue()}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.callNumber', { defaultValue: 'Call Number' })}</span>,
      },
      {
        accessorKey: 'batteryLife',
        id: 'batteryLife',
        cell: (info) => {
          return (
            <>
              <span className="text-ellipsis overflow-hidden block">{info.getValue()}</span>
            </>
          )
        },
        header: () => <span>{t('dashboard.devicesTable.headers.batteryLife', { defaultValue: 'Battery Life' })}</span>,
      },
    ]

    // if (isTenantAdmin)
    //   columns.push({
    //     id: 'Control',
    //     disableSortBy: true,
    //     cell: () => {
    //       return (
    //         <div className="flex items-center justify-center space-x-6">
    //           <BsFillGridFill className="cursor-pointer select-none hover:text-blue-400 transition-colors" />
    //           <BsFillTrashFill className="cursor-pointer select-none hover:text-blue-400 transition-colors" />
    //         </div>
    //       )
    //     },
    //     header: () => <span>{t('dashboard.devicesTable.headers.control')}</span>,
    //   } as ColumnDef<DeviceSummary, any>)
    return columns
  }, [t, isTenantAdmin])

  const table = useReactTable({
    data: devices,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      columnVisibility: {
        // online: false, // Hide this column since it is only used to filter by online/offline
      },
    },
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
  })

  useEffect(() => {
    // Might now be the most elegant solution but works for now, when more filters come through the url will think about refactoring...
    if (!urlParamsRan) {
      setUrlParamsRan(true)
      const online = queryParams.get('online')
      switch (online) {
        case 'true':
          setColumnFilters([{ id: 'online', value: true }])
          break
        case 'false':
          setColumnFilters([{ id: 'online', value: false }])
          break
        default:
          break
      }
    }
  }, [columnFilters, queryParams, urlParamsRan])

  const handleSaveFilter = () => {
    saveFilterMutation.mutate(filterName || '')
    setFilterNamePopoverOpen(false)
  }

  const handleFilterChange = (value: string) => {
    if (value === 'noFilter') {
      setSelectedFilter(value)
      setColumnFilters([])
      return
    }
    const filter = filters?.find((filter) => filter.filterId === value)
    if (filter) {
      setSelectedFilter(filter.filterId)
      setColumnFilters(filter.filters)
    }
  }

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  })

  const { virtualItems: virtualRows, totalSize } = rowVirtualizer

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0

  return (
    <>
      <div className="px-0 sm:px-1 flex justify-between items-center">
        <div>
          <label className="pb-2 font-bold">
            {t('dashboard.devicesTable.savedFilters', { defaultValue: 'Saved filters' })}
          </label>
          <Select value={selectedFilter} onValueChange={handleFilterChange}>
            <SelectTrigger className="w-30 sm:w-60">
              <SelectValue
                placeholder={t('actions.selectFilter', { ns: 'common', defaultValue: 'Select Filter...' })}
              />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {!filters?.length && (
                  <SelectLabel>{t('actions.noFilters', { ns: 'common', defaultValue: 'No filters' })}</SelectLabel>
                )}
                <SelectItem value={'noFilter'}>
                  {t('actions.selectFilter', { ns: 'common', defaultValue: 'Select Filter...' })}
                </SelectItem>
                {filters &&
                  filters.map((filter) => (
                    <SelectItem key={filter.filterId} value={filter.filterId}>
                      {filter.filterName}
                    </SelectItem>
                  ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>
        <div>
          <Popover
            width={200}
            position="left"
            withArrow
            shadow="md"
            opened={filterNamePopoverOpen}
            onChange={setFilterNamePopoverOpen}
          >
            <Popover.Target>
              <Button
                onClick={() => setFilterNamePopoverOpen(!filterNamePopoverOpen)}
                variant="default"
                disabled={!columnFilters?.length}
              >
                {t('actions.saveFilter', { ns: 'common', defaultValue: 'Save filter' })}
              </Button>
            </Popover.Target>
            <Popover.Dropdown>
              <Text size="md" className="pb-2 font-bold">
                <>{t('actions.saveFilterName', { ns: 'common', defaultValue: 'Filter name' })}</>
              </Text>
              <Input
                autoFocus
                className="pb-4"
                value={filterName}
                onChange={(e) => setFilterName(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleSaveFilter()}
              />
              <Button className="text-right" disabled={!filterName} onClick={handleSaveFilter}>
                {t('actions.save', { ns: 'common', defaultValue: 'Save' })}
              </Button>
            </Popover.Dropdown>
          </Popover>
        </div>
      </div>
      <div className="w-full overflow-auto min-w-fit border-gray-300 border rounded-2xl my-8" ref={tableContainerRef}>
        <table className="w-full rounded-2xl table-fixed border-collapse h-px">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        className="py-2 sm:py-3 bg-[#F8FCFF] border border-gray-300 top-0 sticky"
                      >
                        {header.isPlaceholder ? null : (
                          <>
                            <div
                              {...{
                                className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                                onClick: header.column.getToggleSortingHandler(),
                              }}
                            >
                              {flexRender(header.column.columnDef.header, header.getContext())}
                              {header.column.getCanSort()
                                ? {
                                    asc: <BsArrowUp size={15} className="ml-2 inline-block" />,
                                    desc: <BsArrowDown size={15} className="ml-2 inline-block" />,
                                  }[header.column.getIsSorted() as string] ?? (
                                    <BsArrowDownUp size={15} className="ml-2 inline-block" />
                                  )
                                : null}
                            </div>
                          </>
                        )}
                        {header.column.getCanFilter() ? (
                          <div>
                            {header.column.columnDef.filterFn === 'arrIncludesSome' ? (
                              <MultiSelectFilter column={header.column} table={table} options={uniqueStatus} />
                            ) : (
                              <Filter column={header.column} table={table} />
                            )}
                          </div>
                        ) : null}
                      </th>
                    )
                  })}
                </tr>
              )
            })}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<DeviceSummary>
              return (
                <tr key={row.id} className="odd:bg-gray-100 h-full">
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <td
                        key={cell.id}
                        className={`text-center border border-gray-300 ${
                          cell.id.includes('status') ? 'h-full' : 'py-1'
                        }`}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {devices.length === 0 ? <h2 className="text-3xl p-10 text-center">No devices</h2> : null}
    </>
  )
}

export default OzoneDevicesTable
