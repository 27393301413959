import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/components/ui/accordion'

const faqs = [
  {
    id: '1',
    question: 'In Lorem ipsum duis irure ut et ad in duis.?',
    answer:
      'Reprehenderit ut do labore reprehenderit. Velit ea minim veniam commodo sunt non aliquip dolor velit ipsum. Pariatur proident labore irure do velit deserunt nisi non ad. Eiusmod id enim laborum aliquip non.',
  },
  {
    id: '2',
    question: 'In Lorem ipsum duis irure ut et ad in duis.?',
    answer:
      'Reprehenderit ut do labore reprehenderit. Velit ea minim veniam commodo sunt non aliquip dolor velit ipsum. Pariatur proident labore irure do velit deserunt nisi non ad. Eiusmod id enim laborum aliquip non.',
  },
  {
    id: '3',
    question: 'In Lorem ipsum duis irure ut et ad in duis.?',
    answer: 'Reprehenderit ut do labore reprehenderit.',
  },
]

export const SupportPage = () => {
  return (
    <div className="p-6 py-10">
      <section className="mb-8">
        <ul>
          <li className="pb-2">
            <b>Phone:</b> 800-223-1234
          </li>
          <li>
            <b>E-mail</b> <a href="mailto:support@arbo3.com">support@arbo3.com</a>
          </li>
        </ul>
      </section>
      <section className="mb-8">
        <h2 className="font-bold text-2xl mb-4">FAQ</h2>
        <Accordion type="multiple" className="w-full">
          {faqs.map((faq) => (
            <AccordionItem key={faq.id} value={faq.id}>
              <AccordionTrigger>{faq.question}</AccordionTrigger>
              <AccordionContent>{faq.answer}</AccordionContent>
            </AccordionItem>
          ))}
        </Accordion>
      </section>
      <section className="font-bold">System documentation ROS Device</section>
    </div>
  )
}

export default SupportPage
