export const STATUS_LABELS = {
  '1': {
    labelKey: 'dashboard.devicesTable.status.normal',
    label: 'Normal',
    classes: 'bg-[#BA81F7] text-white',
  },
  '2': {
    labelKey: 'dashboard.devicesTable.status.turbo',
    label: 'Turbo',
    classes: 'bg-[#E4594A] text-white',
  },
  '3': {
    labelKey: 'dashboard.devicesTable.status.mobile',
    label: 'Mobile',
    classes: 'bg-[#F4C142] text-white',
  },
  '4': {
    labelKey: 'dashboard.devicesTable.status.routine',
    label: 'Routine',
    classes: 'bg-[#436CF6] text-white',
  },
}
