import '@mantine/core/styles.css'

import '@aws-amplify/ui-react/styles.css'
import '@fontsource/inter'
import { Amplify } from 'aws-amplify'
import { Authenticator } from '@aws-amplify/ui-react'
import awsExports from './aws-exports'
import { Suspense, createContext } from 'react'
import { AmplifyUser } from '@aws-amplify/ui'
import { RouterProvider } from 'react-router-dom'
import getRoutes from './routes'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './i18n'
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from 'react-loading-skeleton'
import { ValidRolesType, useSession } from './lib/session-utils'

import { MantineProvider, createTheme } from '@mantine/core'

const theme = createTheme({
  /** Put your mantine theme override here */
})

Amplify.configure(awsExports)

export const UserContext = createContext<AmplifyUser | undefined>(undefined)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  const { isAuthenticated, loading, role, user } = useSession()
  console.log({ isAuthenticated, loading, role })
  if (loading) return null
  // return (
  //   <Suspense fallback={<Skeleton />}>
  //     <QueryClientProvider client={queryClient}>
  //       <UserContext.Provider value={user}>
  //         <RouterProvider router={getRoutes(role)} />
  //       </UserContext.Provider>
  //     </QueryClientProvider>
  //   </Suspense>
  // )
  return (
    <Authenticator>
      {({ user }) => (
        <Suspense fallback={<Skeleton />}>
          <MantineProvider theme={theme}>
            <QueryClientProvider client={queryClient}>
              <UserContext.Provider value={user}>
                <RouterProvider
                  router={getRoutes((user?.attributes?.['custom:role'] as ValidRolesType) || 'anonymous')}
                />
              </UserContext.Provider>
            </QueryClientProvider>
          </MantineProvider>
        </Suspense>
      )}
    </Authenticator>
  )
}
