import { DashboardResponse, DeviceHistoryResponse } from './ozone-devices.types'
import axiosInstance from '../axios'
import { mockOzoneDevices } from '../mock-ozone-devices'
import { ColumnFiltersState } from '@tanstack/react-table'

export type SaveFilterParam = { filterName: string; filterId: string; filters: ColumnFiltersState }

export const getOzoneDevices = async () => {
  const { data } = await axiosInstance.get('ozone-devices')
  return data
}

export const getOzoneDevicesDashboard = async () => {
  const { data: realDevicesData } = await axiosInstance.get<DashboardResponse>('dashboard')
  // const { data: devices } = await axiosInstance.get<DashboardResponse>('devices')
  // console.log({ realDevicesData })
  // console.log({ devices })

  return {
    ...realDevicesData,
    devices: [
      ...realDevicesData.devices.map((device) => {
        const randomItemIdx = Math.floor(mockOzoneDevices.devices.length * Math.random()) || 0
        const randomItem = mockOzoneDevices.devices[randomItemIdx]
        return { ...randomItem, ...device, live: true }
      }),
      ...mockOzoneDevices.devices,
    ],
  }

  // const ozoneLocal = localStorage.getItem('ozoneDevices')
  // if (!ozoneLocal) {
  //   localStorage.setItem('ozoneDevices', JSON.stringify(mockOzoneDevices))
  // }
  // const promise = new Promise<DashboardResponse>((resolve) => {
  //   resolve(ozoneLocal ? JSON.parse(ozoneLocal) : mockOzoneDevices)
  // })
  // return promise
}

export const getOzoneDeviceHistory = async (deviceId: string) => {
  try {
    const { data } = await axiosInstance.get<DeviceHistoryResponse>(`dashboard/${deviceId}`)
    return data;
  } catch (e) {
    console.error(e);
    const { data } = await axiosInstance.get<DeviceHistoryResponse>(`dashboard/247260508314`)
    return data;
  }
}

export const updateDeviceOzoneById = async ({ id, ozoneLevel }: { id: string; ozoneLevel: number | string }) => {
  // const { data } = await axiosInstance.patch<any>(`devices/${id}`, { ozoneLevel })

  const { data } = await axiosInstance.patch<any>(`devices/0080E12775D1`, { ozoneLevel })
  return data
}

export const getSavedFilters = () => {
  const filters = localStorage.getItem('ozoneFilters')
  return JSON.parse(filters || '[]') as SaveFilterParam[]
}

export const saveFilter = (filter: SaveFilterParam) => {
  const promise = new Promise<SaveFilterParam>((resolve) => {
    const filters = getSavedFilters()
    localStorage.setItem('ozoneFilters', JSON.stringify([...filters, filter]))
    resolve(filter)
  })
  return promise
}

export const deleteFilter = async (filterId: string) => {
  return true
}
