import { Navigate } from 'react-router-dom'
import { useSession } from 'src/lib/session-utils'

export const Login = () => {
  const { loading, isAuthenticated } = useSession()
  if (!loading && isAuthenticated) return <Navigate to="/" replace />
  return <div className="text-white">hello</div>
}

export default Login
