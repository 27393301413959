import { Fragment, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Auth, Hub } from 'aws-amplify'
import { AmplifyUser } from '@aws-amplify/ui'

export const getSession = async () => {
  return await Auth.currentSession()
}

const Roles = ['anonymous', 'tenantAdmin', 'endUser', 'g1Admin'] as const

type RolesTuple = typeof Roles
export type ValidRolesType = RolesTuple[number]

export const hasRoleAsync = async (roles: ValidRolesType[]) => {
  const session = await getSession()
  const role = session.getIdToken()?.payload?.['custom:role'] as ValidRolesType
  return roles.includes(role)
}

export const getUserRole = async () => {
  const session = await getSession()
  return session.getIdToken()?.payload?.['custom:role'] as ValidRolesType
}

export const hasRole = (roles: ValidRolesType[], role: ValidRolesType) => {
  return roles.includes(role)
}

export const useHasRole = (roles: ValidRolesType[]) => {
  const [isRole, setIsRole] = useState<boolean>(true)

  const doHasRole = useCallback(async () => {
    setIsRole(await hasRoleAsync(roles))
  }, [roles])

  useEffect(() => {
    doHasRole()
  }, [doHasRole])
  return isRole
}

export const RoleAccess = async ({ roles, children }: PropsWithChildren<{ roles: ValidRolesType[] }>) => {
  const hasRole = useHasRole(roles)
  if (!hasRole) return null
  return <Fragment>{children}</Fragment>
}

export const useSession = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [role, setRole] = useState<ValidRolesType>('anonymous')
  const [user, setUser] = useState<AmplifyUser>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function isAuthenticated() {
      try {
        setLoading(true)
        const user = await Auth.currentAuthenticatedUser()
        setRole(user.attributes['custom:role'])
        setUser(user)
        setIsAuthenticated(true)
        setLoading(false)
      } catch {
        setLoading(false)
        setIsAuthenticated(false)
      }
    }

    const hubListener = Hub.listen('auth', (data) => {
      switch (data.payload?.event) {
        case 'signIn':
        case 'signOut':
          isAuthenticated()
          break
        default:
          break
      }
    })

    isAuthenticated()
    return () => {
      hubListener()
    }
  }, [])
  return { isAuthenticated, loading, role, user }
}
