import { Card, CardContent, CardFooter, CardHeader } from '../ui/card'
import { Progress } from '../ui/progress'
import CardIcon from '../../svg/card-icon-active.svg'
import CardIconInactive from '../../svg/card-icon-inactive.svg'
import { useTranslation } from 'react-i18next'

interface DashboardCardInfoProps {
  isOffline?: boolean
  totalDevices: number
  responseRate: number
  className?: string
}

const DashboardCardInfo = ({ isOffline, totalDevices, responseRate, className }: DashboardCardInfoProps) => {
  const { t } = useTranslation()
  return (
    <Card className={`${className || ''} rounded-xl shadow-md`}>
      <CardHeader className="flex flex-row justify-between align-middle">
        <h2 className="text-2xl text-black/70">{`${
          isOffline ? t('dashboard.card-info.offline') : t('dashboard.card-info.online')
        }`}</h2>
        {isOffline ? (
          <img src={CardIconInactive} alt="card icon" className="mt-0 w-7 h-6" />
        ) : (
          <img src={CardIcon} alt="card icon" className="mt-0 w-7 h-6" />
        )}
      </CardHeader>
      <CardContent>
        <h2 className="text-5xl font-medium leading-3 mb-3">{totalDevices}</h2>
      </CardContent>
      <CardFooter className="border-t border-color border-t-blue-50 flex flex-col">
        <Progress value={responseRate} className="bg-blue-50 mt-2" />
        <p className="text-black/70 text-sm">{`Response Rate: ${responseRate}%`}</p>
      </CardFooter>
    </Card>
  )
}

export default DashboardCardInfo
