export const mockOzoneDevices = {
  total: {
    online: 5,
    offline: 1,
  },
  devices: [
    {
      userId: 'appuser',
      deviceid: '0080E12775D1',
      status: 2,
      online: true,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734', y: '121.5248' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '11:00',
      },
      city: 'Taichung',
      callNumber: '3334555',
      batteryLife: 'Good',
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: 'luy87tgfiue',
      status: 2,
      online: true,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734 ', y: '121.5248' },
      ros: 8,
      ozoneTarget: 67,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taipei',
      callNumber: '123456',
      batteryLife: 'Good',
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '001122AABBCC',
      status: 2,
      online: true,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734', y: '121.5248' },
      ros: 8,
      ozoneTarget: 67,
      shift: {
        from: '6:00',
        to: '16:00',
      },
      city: 'Chiayi',
      callNumber: '123456',
      batteryLife: 'Critical',
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: 'ac5d5c1f6f9e',
      status: 2,
      online: true,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734', y: '121.5248' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taichung',
      callNumber: '123456',
      batteryLife: 'Critical',
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: 'd8cb8a1de670',
      status: 2,
      online: true,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734', y: '121.5248' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '19:00',
      },
      city: 'Taipei',
      callNumber: '233452',
      batteryLife: 'Good',
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: 'SAMPLESDK',
      status: 2,
      online: true,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734', y: '121.5248' },
      ros: 8,
      ozoneTarget: 67,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Chiayi',
      callNumber: '233452',
      batteryLife: 'Low',
      live: false,
    },
    {
      userId: 'IM_OFFLINE',
      deviceid: '0080E12775123',
      status: 2,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.102106258622936', y: '121.51005171358432' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taichung',
      callNumber: '123456',
      batteryLife: 'Good',
      online: false,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '0080E127375D1',
      status: 2,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0831693164913', y: '121.46863633619544' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Chiayi',
      callNumber: '123456',
      batteryLife: 'Good',
      online: false,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '0080E312775D1',
      status: 2,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.030679327892194', y: '121.59896333567924' },
      ros: 8,
      ozoneTarget: 2,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taichung',
      callNumber: '233452',
      batteryLife: 'Low',
      online: true,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '0080E152775D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.12043826166407', y: '121.369176257642' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Tainan',
      callNumber: '233452',
      batteryLife: 'Low',
      online: true,
      live: false,
    },
    {
      userId: 'appuser4',
      deviceid: '0080E122775D1',
      status: 2,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.127629206606773', y: '121.66484886865928' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Tainan',
      callNumber: '233452',
      batteryLife: 'Low',
      online: true,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '0080E112775D1',
      status: 3,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.020702053716363', y: '121.49805919064954' },
      ros: 8,
      ozoneTarget: 15,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Kaohsiung',
      callNumber: '23456745',
      batteryLife: 'Critical',
      online: true,
      live: false,
    },
    {
      userId: 'appuser7',
      deviceid: '00801E12775D1',
      status: 4,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.102949425341276', y: '121.43794992357459' },
      ros: 8,
      ozoneTarget: 15,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taipei',
      callNumber: '23456745',
      batteryLife: 'Low',
      online: true,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '00840E12775D1',
      status: 2,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.057662971172636', y: '121.54571338436662' },
      ros: 8,
      ozoneTarget: 15,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taipei',
      callNumber: '123456',
      batteryLife: 'Good',
      online: false,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '00580E12775D1',
      status: 2,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.026263257410026', y: '121.36574659976087' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Kaohsiung',
      callNumber: '123456',
      batteryLife: 'Critical',
      online: true,
      live: false,
    },
    {
      userId: 'appuser7',
      deviceid: '02080E12775D1',
      status: 3,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '24.989774669415972', y: '121.49554664139023' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Kaohsiung',
      callNumber: '23456745',
      batteryLife: 'Critical',
      online: true,
      live: false,
    },
    {
      userId: 'appuser7',
      deviceid: '008044E12775D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '24.987163067844538', y: '121.29164058221149' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Tainan',
      callNumber: '23456745',
      batteryLife: 'Good',
      online: true,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '0080E1257575D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.05886085105566', y: '121.36019883613776' },
      ros: 8,
      ozoneTarget: 15,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Kaohsiung',
      callNumber: '23456745',
      batteryLife: 'Low',
      online: false,
      live: false,
    },
    {
      userId: 'appuser5',
      deviceid: '0080E1227175D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.01878650668221', y: '121.25478129864752' },
      ros: 8,
      ozoneTarget: 15,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taipei',
      callNumber: '3334555',
      batteryLife: 'Low',
      online: true,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '00850E212775D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '24.950184507279406', y: '121.51943090251342' },
      ros: 8,
      ozoneTarget: 15,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Chiayi',
      callNumber: '3334555',
      batteryLife: 'Critical',
      online: true,
      live: false,
    },
    {
      userId: 'appuser4',
      deviceid: '02085033E12775D1',
      status: 4,
      ozoneLevel: 2,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '24.959096019932215', y: '121.39730383369135' },
      ros: 8,
      ozoneTarget: 67,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Tainan',
      callNumber: '123456',
      batteryLife: 'Critical',
      online: true,
      live: false,
    },
    {
      userId: 'appuser',
      deviceid: '0080E15427275D1',
      status: 2,
      ozoneLevel: 3,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '28.175484865534532', y: '-108.2126580746883' },
      ros: 8,
      ozoneTarget: 67,
      shift: {
        from: '6:00',
        to: '16:00',
      },
      city: 'Taipei',
      callNumber: '123456',
      batteryLife: 'Low',
      online: true,
      live: false,
    },
    {
      userId: 'appuser3',
      deviceid: '00830E1227755D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.086014902709007', y: '121.37739982448696' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Chiayi',
      callNumber: '3334555',
      batteryLife: 'Good',
      online: true,
      live: false,
    },
    {
      userId: 'appuser1',
      deviceid: '00802E1257745D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.0734', y: '121.5248' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '16:00',
      },
      city: 'Chiayi',
      callNumber: '3334555',
      batteryLife: 'Good',
      online: true,
      live: false,
    },
    {
      userId: 'appuser1',
      deviceid: '00805E1227725D1',
      status: 1,
      ozoneLevel: 9,
      ts: '2023-09-06T14:23:20.062Z',
      gps: { x: '25.034343612697366', y: '121.55226848260938' },
      ros: 8,
      ozoneTarget: 10,
      shift: {
        from: '6:00',
        to: '14:00',
      },
      city: 'Taipei',
      callNumber: '3334555',
      batteryLife: 'Critical',
      online: true,
      live: false,
    },
  ],
  center: {
    x: 25.06059125,
    y: 121.4586459,
  },
  offset: 0,
  limit: 5,
  filter: null,
}
