import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import detector from 'i18next-browser-languagedetector'

// default namespace
export const ns = ['glossary', 'common']

i18next
  .use(HttpApi)
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: {
      en: ['en-US'],
      zh: ['zh-TW'],
      default: ['en-US'],
    },
    ns,
    defaultNS: 'glossary',
    supportedLngs: ['en-US', 'zh-TW', 'en', 'zh'],
  })

export default i18next
