import { useQuery } from '@tanstack/react-query'
import Skeleton from 'react-loading-skeleton'
import OzoneDevicesTable from 'src/components/ozone-devices-table'
import TopNavigation from 'src/components/top-navigation'
import { getOzoneDevicesDashboard } from 'src/services/ozone-devices/ozone-devices'

export const Devices = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['ozoneDevices'],
    queryFn: getOzoneDevicesDashboard,
  })
  return (
    <>
      <TopNavigation />
      <div className="grid overflow-hidden" style={{ gridTemplateRows: 'auto 1fr' }}>
        {isFetching ? (
          <div>
            <Skeleton className="h-20 mt-8 mb-2 rounded-2xl rounded-b-none" />
            <Skeleton count={8} className="h-12" />
          </div>
        ) : (
          <OzoneDevicesTable devices={data?.devices || []} />
        )}
      </div>
    </>
  )
}

export default Devices
