import { PropsWithChildren, useCallback } from 'react'
import { useQuery, useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { getOzoneDeviceHistory, updateDeviceOzoneById } from '../../services/ozone-devices/ozone-devices'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import Skeleton from 'react-loading-skeleton'
import { LineChart, Line, XAxis, Tooltip, ResponsiveContainer } from 'recharts'
import BatteryIcon from '../../svg/battery-icon.svg'
import DebouncedInput from '../ui/debounceInput'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../ui/select'
import { STATUS_LABELS } from 'src/lib/constants'

const statusLabelOptions = Object.keys(STATUS_LABELS).map((key) => ({
  value: key,
  label: STATUS_LABELS[key as keyof typeof STATUS_LABELS].label,
}))

interface DeviceHistoryDialogProps {
  open?: boolean
  deviceId: string
}

const CustomTooltip = ({ active, payload, label }: { active?: boolean; payload?: any[]; label?: string }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-white h-16 w-16 text-center leading-none rounded-full flex items-center justify-center flex-col">
        <p className="text-black font-semibold text-3xl -mb-1">{payload[0].value}</p>
        <p className="text-black font-semibold">ppb</p>
      </div>
    )
  }

  return null
}

const DeviceHistoryDialog = ({ deviceId, children }: PropsWithChildren<DeviceHistoryDialogProps>) => {
  const { t } = useTranslation()
  const { data, isFetching, refetch } = useQuery({
    queryKey: ['deviceHistory', deviceId],
    queryFn: () => getOzoneDeviceHistory(deviceId),
    enabled: false,
    retry: false,
  })

  const updateOzoneLevel = useMutation({
    mutationFn: updateDeviceOzoneById,
  })

  const fetchHistory = useCallback(
    (open: boolean) => {
      if (open) refetch()
    },
    [refetch],
  )

  return (
    <Dialog onOpenChange={fetchHistory}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="sm:max-w-[85%] md:max-w-[75%] lg:max-w-[60%]">
        <div className="grid gap-4 p-1">
          {!data || isFetching ? (
            <Skeleton className="h-[500px] rounded-2xl" />
          ) : (
            <>
              <div className="rounded-2xl shadow-md h-[500px] overflow-hidden">
                <div className="absolute pl-6 pt-4 text-white w-full flex justify-between">
                  <div className="flex flex-col font-semibold">
                    <div className="text-4xl">{data.description}</div>
                    <div className="text-3xl">{t('dashboard.devicesTable.deviceOzoneSettingModal.targetSetting')}</div>
                    <div className="text-[#2DFFFF] text-8xl">{data.ozoneLevel}</div>
                  </div>
                  <div className="mr-10">
                    <img src={BatteryIcon} alt="card icon" className="pt-1 w-32 h-auto" />
                  </div>
                </div>
                <ResponsiveContainer width="100%" height="100%" className="bg-[#0B2D5E]">
                  <LineChart
                    width={600}
                    height={500}
                    data={data.history}
                    margin={{
                      top: 200,
                      right: 0,
                      left: 0,
                      bottom: 40,
                    }}
                  >
                    <Tooltip content={<CustomTooltip />} />
                    <Line
                      type="monotone"
                      dataKey="ozoneLevel"
                      stroke="#FFFFFF"
                      dot={false}
                      strokeWidth={4}
                      activeDot={{ stroke: 'white', fill: '#0B2D5E', r: 7 }}
                    />
                    <XAxis
                      dataKey="reference"
                      tickLine={false}
                      tick={{ stroke: 'white', fill: 'white' }}
                      axisLine={{ stroke: 'white' }}
                      interval="preserveStartEnd"
                      padding="gap"
                    />
                  </LineChart>
                </ResponsiveContainer>
              </div>
              <div className="grid grid-cols-12 gap-4 items-center py-4 px-8">
                <div className="col-span-8">
                  <h2 className="text-gray-400 text-2xl font-bold">OZone Editing</h2>
                  <DebouncedInput
                    type="number"
                    className="bg-[#E1F4FF] text-center text-5xl font-bold py-8 text-gray-500"
                    placeholder=""
                    value={data.ozoneLevel}
                    onChange={(value) =>
                      value !== data.ozoneLevel && updateOzoneLevel.mutate({ id: deviceId, ozoneLevel: value })
                    }
                  />
                </div>
                <div className="col-span-4">
                  <h2 className="pb-6 font-bold text-2xl text-gray-400">Mode:</h2>
                  <Select defaultValue={'2'}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select a mode" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectLabel>Mode</SelectLabel>
                        {statusLabelOptions.map((statusOption) => (
                          <SelectItem key={statusOption.value} value={statusOption.value}>
                            {statusOption.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </div>
            </>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeviceHistoryDialog
