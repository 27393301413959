import { PropsWithChildren } from 'react'
import { Dialog, DialogContent, DialogTrigger } from '../ui/dialog'
import { GoogleMap, MarkerF } from '@react-google-maps/api'
import DeviceHistoryDialog from '../device-ozone-history'
import { getMarker } from 'src/lib/ui-utils'

interface FullScreenMapDialogProps {
  markers?: {
    lat: number
    lng: number
    online: boolean
    deviceId: string
  }[]
}

const FullScreenMapDialog = ({ markers, children }: PropsWithChildren<FullScreenMapDialogProps>) => {
  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent
        className="min-w-full left-0 top-0 transform-none min-h-screen"
        closeClassName="right-4 top-4 sm:right-4 sm:top-4"
      >
        <GoogleMap
          mapContainerClassName="rounded-xl"
          center={{ lat: 25.0734, lng: 121.5248 }}
          zoom={10}
          options={{
            fullscreenControl: false,
            mapTypeControl: false,
            streetViewControl: false,
          }}
        >
          {markers?.map((marker, index) => (
            <DeviceHistoryDialog key={index} deviceId={marker.deviceId}>
              <MarkerF
                icon={getMarker(marker.online ? '#037B01' : '#DE0A13')}
                position={{ lat: marker.lat, lng: marker.lng }}
              />
            </DeviceHistoryDialog>
          ))}
        </GoogleMap>
      </DialogContent>
    </Dialog>
  )
}

export default FullScreenMapDialog
