import { useQuery } from '@tanstack/react-query'
import { getOzoneDevicesDashboard, getSavedFilters } from 'src/services/ozone-devices/ozone-devices'
import Skeleton from 'react-loading-skeleton'
import DashboardCardInfo from 'src/components/dashboard-card-info'
import { GoogleMap, useLoadScript, MarkerF } from '@react-google-maps/api'
import { useMemo, useState } from 'react'
import Decimal from 'decimal.js'
import TopNavigation from 'src/components/top-navigation'
import { Card } from 'src/components/ui/card'
import { HiOutlinePlus } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import DeviceHistoryDialog from 'src/components/device-ozone-history'
import { MdFullscreen } from 'react-icons/md'
import { getMarker } from 'src/lib/ui-utils'
import FullScreenMapDialog from 'src/components/full-screen-map-dialog'

const calculateResponse = (total: number, devices: number) => {
  return Number(new Decimal(devices).times(100).dividedBy(total).toFixed(2))
}

const Dashboard = () => {
  const { data, isFetching } = useQuery({
    queryKey: ['ozoneDevices'],
    queryFn: getOzoneDevicesDashboard,
  })

  const { data: filters } = useQuery({
    queryKey: ['deviceFilters'],
    queryFn: getSavedFilters,
    retry: false,
  })

  const { isLoaded: isMapLoaded } = useLoadScript({
    // TODO: Replace with env
    googleMapsApiKey: 'AIzaSyDjtXBKPBnoTb86C4GQZ6cvvv4tRiIT8YM',
  })
  const markers = useMemo(() => {
    return data?.devices.map((device) => {
      const { x: lat, y: lng } = device.gps
      console.log(device)
      return {
        lat: Number(lat),
        lng: Number(lng),
        online: device.online,
        deviceId: device.deviceid,
        live: device.live,
      }
    })
  }, [data?.devices])

  const totalDevicesCount = useMemo(() => (data?.total.online || 0) + (data?.total.offline || 0), [data?.total])

  return (
    <>
      <TopNavigation />
      <div className="grid overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 pb-4 gap-4">
          {!isFetching && isMapLoaded && data?.total ? (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 items-start content-start">
                <Link to={`/devices?online=true`}>
                  <DashboardCardInfo
                    className="h-52"
                    totalDevices={data.total.online}
                    responseRate={calculateResponse(totalDevicesCount, data.total.online)}
                  />
                </Link>
                <Link to={`/devices?online=false`}>
                  <DashboardCardInfo
                    isOffline
                    className="h-52"
                    totalDevices={data.total.offline}
                    responseRate={calculateResponse(totalDevicesCount, data.total.offline)}
                  />
                </Link>
                {filters &&
                  filters.map((filter) => (
                    <Link key={filter.filterId} to="/devices" state={{ savedFilter: filter }}>
                      <Card className="rounded-xl shadow-md h-52 flex flex-col hover:text-gray-400 transition-colors p-6">
                        <h2 className="text-2xl text-black/70 mb-10 text-left">Filter</h2>
                        <p className="text-lg text-center">{filter.filterName}</p>
                      </Card>
                    </Link>
                  ))}
                {/* <button>
                  <Card className="rounded-xl shadow-md h-52 flex items-center justify-center hover:text-gray-400 transition-colors">
                    <HiOutlinePlus size={40} />
                  </Card>
                </button> */}
              </div>
              <GoogleMap
                mapContainerClassName="rounded-xl"
                center={{ lat: data.center.x, lng: data.center.y }}
                zoom={10}
                options={{
                  fullscreenControl: false,
                  mapTypeControl: false,
                  streetViewControl: false,
                }}
              >
                <FullScreenMapDialog markers={markers}>
                  <button className="absolute right-2 top-2 p-2 bold text-white transition-colors hover:text-gray-100">
                    <MdFullscreen size={35} />
                  </button>
                </FullScreenMapDialog>
                {markers?.map((marker, index) => (
                  <DeviceHistoryDialog key={index} deviceId={marker.deviceId}>
                    <MarkerF
                      icon={getMarker(marker.live ? '#73CDF8' : marker.online ? '#037B01' : '#DE0A13')}
                      position={{ lat: marker.lat, lng: marker.lng }}
                    />
                  </DeviceHistoryDialog>
                ))}
              </GoogleMap>
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4 items-start">
                <div className="inline-block">
                  <Skeleton className="h-52 rounded-xl" />
                </div>
                <div className="inline-block">
                  <Skeleton className="h-52 rounded-xl" />
                </div>
              </div>
              <div className="">
                <Skeleton className="h-full rounded-xl" />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default Dashboard
